<template>
  <div
    style="
      background-color: #f8f8fd;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      padding-bottom: 60px;
    "
  >
    <!-- <div
      class="top_bg"
      style="background-image: url('img/myIncome/re_top_bg.png')"
    >
      <nav-bar
        class="title_top"
        :icon="'img/backb.png'"
        :textColor="'#111'"
        :backColor="'none'"
      />
    </div> -->
    <div class="re_title">充值给</div>
    <div
      style="
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding-left: 10px;
      "
    >
      <input
        class="input"
        type="number"
        v-model="uid"
        placeholder="请输入充值的uid"
      />
      <div class="button" @click="getUserInfo">确定</div>
    </div>
    <div class="info_box" v-if="userInfo != null">
      <img class="head" :src="userInfo.head_portrait" alt="" />
      <div>
        <div>{{ userInfo.nickname }}</div>
        <div>ID:{{ userInfo.uid }}</div>
      </div>
    </div>
    <div>
      <div class="re_title">请选择充值金额</div>
      <div class="re_box">
        <div
          class="re_item"
          v-for="(item, index) in list"
          :key="index"
          @click="payAction(item)"
        >
          <div class="re_item_top">
            <span class="g_color">{{ item.diamonds }}</span>
            <img class="coin_sm" src="img/bi.png" alt="" />
          </div>
          <div class="re_item_top">
            <span class="s_color">{{ item.gold }}</span>
            <img class="coin_sm" src="img/sliver.png" alt="" />
          </div>
          <div class="money">{{ item.money + "元" }}</div>
        </div>
      </div>
    </div>
    <div class="mask" v-if="showMask">
      <div class="modal_content">
        <div class="modal_title">支付提示</div>
        <div class="tip_content">本次支付将优惠{{ deduct_money }}元</div>
        <div class="btns_box">
          <div class="btn_item_cancel" @click="showMask = false">取消</div>
          <div class="btn_item_enter" @click="confirm">去支付</div>
        </div>
      </div>
    </div>
    <div class="agreement" @click="toAgreement">《充值服务协议》</div>
  </div>
</template>
<script src="../../utils/jweixin.js"></script>
<script>
import { wxPay, rechargeList, payUserInfo, getOpenId } from "@/api/wallet";
export default {
  name: "rechargeIndex",
  data() {
    return {
      userInfo: null,
      uid: "",
      list: [],
      payData: {},
      showMask: false,
      open_id: "",
      deduct_money: 0,
    };
  },
  created() {
    this.getReList();
    if (this.getQueryString("code")) {
      let code = this.getQueryString("code");
      this.wechatLogin(code);
    } else {
      this.getCode();
    }
  },
  methods: {
    getCode() {
      let url = "https://api.whsnkj.shop/h5/index.html#/rechargeIndex";
      // let url = 'http://test.wuhanshuiningkeji.cn/h5/index.html#/myIncome'
      let rUrl = encodeURIComponent(url);
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb0faffcc52671f53&redirect_uri=" +
        rUrl +
        "&response_type=code&scope=snsapi_userinfo&connect_redirect=1&state=state#wechat_redirect";
    },
    wechatLogin(code) {
      let params = {
        code: code,
      };
      getOpenId(params)
        .then(
          (response) => {
            if (response.code == 10000) {
              this.open_id = response.data.openid;
              // alert(this.openId)
              // localStorage.removeItem("openid");
              // localStorage.setItem("openid", this.openId);
            } else {
              this.$toast.fail(response.msg);
            }
          },
          (error) => {
            console.log(error);
            this.$toast.fail(error);
          }
        )
        .catch((error) => {
          this.$toast.fail(error);
        });
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    getReList() {
      let params = {};
      rechargeList(params).then(
        (res) => {
          if (res.code == 10000) {
            this.list = res.data;
          }
        },
        (error) => {
          console.log(error);
          this.$toast.fail(error);
        }
      );
    },
    getUserInfo() {
      let u_id = this.uid;
      let params = {
        uid: u_id,
      };
      payUserInfo(params)
        .then(
          (response) => {
            this.userInfo = response.data;
            console.log(this.userInfo);
          },
          (error) => {
            console.log(error);
            this.$toast.fail(error);
          }
        )
        .catch((error) => {
          this.$toast.fail(error);
        });
    },
    payAction(item) {
      if(this.uid==''){
        this.$toast.fail('请输入uid');
        return false
      }
      let params = {
        openid: this.open_id,
        uid: this.uid,
        recharge_id: item.id,
      };
      wxPay(params)
        .then(
          (response) => {
            console.log(response);
            if (response.code == 10000) {
              this.payData = response.data;
              this.deduct_money = response.data.deduct_money;
              this.showMask = true;
            }
          },
          (error) => {
            console.log(error);
            this.$toast.fail(error);
          }
        )
        .catch((error) => {
          this.$toast.fail(error);
        });
    },
    confirm() {
      this.showMask = false;
      this.wxReadyToPay();
    },
    onBridgeReady() {
      let that = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: that.payData.appId, //公众号ID，由商户传入
          timeStamp: that.payData.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: that.payData.nonceStr, //随机串
          package: that.payData.package,
          signType: that.payData.signType, //微信签名方式：
          paySign: that.payData.paySign, //微信签名
        },
        function (res) {
          this.getUserWallet();
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        }
      );
    },
    wxReadyToPay() {
      let that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            that.onBridgeReady(),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", that.onBridgeReady());
          document.attachEvent("onWeixinJSBridgeReady", that.onBridgeReady());
        }
      } else {
        that.onBridgeReady();
      }
    },
    toAgreement() {
      this.$router.push({ path: "/recharge", query: {} });
    },
  },
};
</script>
<style scoped>
.top_bg {
  width: 100vw;
  height: 200px;
}
.head {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
}
.info_box {
  width: 700px;
  height: 80px;
  margin: 10px auto 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.gold_info_box {
  width: 344px;
  height: 221px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.silver_info_box {
  width: 344px;
  height: 221px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.tip_box {
  width: 700px;
  height: 117px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 40px auto 0;
}
.input {
  display: block;
  width: 500px;
  height: 60px;
  border-radius: 25px;
  border: 1px solid #f1f1fd;
  font-size: 28px;
  text-indent: 20px;
  color: #111;
}
.button {
  width: 100px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  margin-left: 20px;
  background: #fff;
  border-radius: 10px;
  color: #9664ff;
}
.gold_info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: #ffdba1;
  margin-top: 52px;
}
.coin {
  width: 40px;
  height: 40px;
}
.gold_title {
  font-size: 26px;
  color: #ffffff;
  text-align: center;
  margin-top: 16px;
}
.re_title {
  width: 694px;
  height: 40px;
  font-size: 28px;
  line-height: 40px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #111;
  margin: 55px auto 0;
  font-weight: 700;
}
.re_box {
  width: 700px;
  margin: 25px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.re_item {
  width: 218px;
  height: 266px;
  background-color: #fff;
  margin-top: 17px;
  border-radius: 16px;
}
.coin_sm {
  width: 35px;
  height: 35px;
}
.re_item_top {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  margin-top: 30px;
}
.g_color {
  color: #111;
}
.s_color {
  color: #999999;
}
.money {
  font-size: 36px;
  color: #9664ff;
  width: 100%;
  line-height: 80px;
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid #f8f8fd;
}
.mask {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.modal_content {
  width: 500px;
  height: 300px;
  margin: 700px auto 0;
  overflow: hidden;
  z-index: 999;
  background-color: #ffffff;
  position: relative;
  border-radius: 30px;
}

.modal_title {
  text-align: center;
  font-size: 32px;
  color: #111111;
  margin-top: 10px;
}

.btns_box {
  height: 98px;
  width: 500px;
  display: flex;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #efefef;
}

.btn_item_cancel {
  width: 249px;
  height: 98px;
  color: #111111;
  line-height: 98px;
  text-align: center;
  font-size: 30px;
  border-right: 1px solid #efefef;
}

.btn_item_enter {
  width: 250px;
  height: 98px;
  color: #409eff;
  line-height: 98px;
  text-align: center;
  font-size: 30px;
}
.tip_content {
  text-align: center;
  margin-top: 45px;
  font-size: 26px;
  color: #666666;
}
.agreement {
  text-align: center;
  width: 100%;
  height: 80px;
  color: #111;
  line-height: 80px;
  position: fixed;
  left: 0;
  bottom: 0;
}
</style>